import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getLogs } from "../API";
import { Co2Sharp } from "@mui/icons-material";

export const TripLog = () => {
  const { startDate = new Date(), endDate = new Date() } = useSelector(
    (state) => state.datefilterreducer
  );
  const [selectedDate, setSelectedDate] = useState(new Date(startDate));
  const [logsData, setLogsData] = useState([]);
  const token = localStorage.getItem("token");
  const fleetData = JSON.parse(localStorage.getItem("fleetData"));
  const fleetId = fleetData.fleetId;

  useEffect(() => {
    const dateString =
      (selectedDate < 10
        ? "0" + selectedDate.getDate()
        : selectedDate.getDate()) +
      "-" +
      (selectedDate.getMonth() < 9
        ? "0" + (selectedDate.getMonth() + 1)
        : selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getFullYear();
    getLogs({ dateString, token, fleetId }).then((res) =>
      setLogsData(res.data.logs)
    );
  }, [selectedDate]);

  const isDatesEqual = (d1, d2) => {
    return (
      d1.getDate() === d2.getDate() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getFullYear() === d2.getFullYear()
    );
  };
  const getDatesArray = (startDate, endDate) => {
    const temp = [];
    let currentDate = new Date(startDate);
    const end = new Date(endDate);

    while (currentDate <= end) {
      temp.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    if (!isDatesEqual(selectedDate, temp[0])) {
      setSelectedDate(temp[0]);
    }

    return temp;
  };

  const dates = useMemo(
    () => getDatesArray(startDate, endDate),
    [startDate, endDate]
  );

  const getDatesInRange = () => {
    //   return dates.map((date) => <div className={`${isDatesEqual(selectedDate,date)?"bg-[#0ab7d3]":"bg-[#272f42]"} rounded-lg pt-1 pr-[18px] pb-1 pl-[18px] flex flex-row  items-center justify-center shrink-0  hover:cursor-pointer`} onClick={() => setSelectedDate(date)}>
    return dates.map((date) => {
      const dateString =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
        "-" +
        (date.getMonth() < 9
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) +
        "-" +
        date.getFullYear();

      return (
        <div
          className={`${
            isDatesEqual(selectedDate, date) ? "bg-[#0ab7d3]" : "bg-[#272f42]"
          } rounded-lg  text-[#ffffff] text-left font-['Poppins'] hover:cursor-pointer text-sm uppercase h-full min-w-[115px] py-1 px-[14px] mr-2`}
          onClick={() => setSelectedDate(date)}
        >
          <div className="w-full mx-auto text-center">
            {/* {date.getDate()+"-"+(date.getMonth()+1)+"-"+date.getFullYear()} */}
            {dateString}
          </div>
        </div>
      );
    });
  };

  const getLogsCards = () => {
    let logsCards = [];
    if (logsData.length > 0) {
      logsCards = logsData.map((log) => {
        let loadedLogs = [];
        let unLoadedLogs = [];

        // let newLogs = log?.logs?.filter((item) => item.action.indexOf("loaded")!==-1 || item.action.indexOf("unloaded")!==-1)
        // for(let i=0;i<newLogs.length;i++){
        //   let temp = []
        //   let createdAt = newLogs[i]?.createdAt?.split("T")?.[1].split(":");

        //   temp.push(  <div className="flex flex-col gap-2 items-start justify-start shrink-0 w-[175px] ">
        //     <div className="flex flex-col gap-0 items-start justify-start self-stretch shrink-0 ">
        //       <div className="text-[#ffffff] text-left font-['-',_sans-serif] text-sm font-normal  self-stretch flex items-center justify-start">
        //         <div className="flex flex-row font-[Poppins] gap-1">
        //           <div className="loaded-by-ritukar-vijay-span w-full">
        //             {newLogs[i]?.action} <span className="text-left text-[10px] font-[Poppins] ">{(createdAt?.length>1)?(`${createdAt?.[0]}:${createdAt?.[1]}`):""}</span>
        //           </div>
        //         </div>{" "}
        //       </div>

        //     </div>
        //   </div>)

        //   if(i+1<log.logs.length){
        //     let createdAt = newLogs[i+1]?.createdAt?.split("T")?.[1].split(":");

        //     temp.push(
        //       <div className="flex flex-col items-end justify-start shrink-0 w-40">
        //       <div className="flex flex-col gap-0 justify-start shrink-0 w-full ">
        //         <div className="text-[#ffffff] text-left font-['-',_sans-serif] text-sm font-normal  self-stretch flex items-center justify-start">
        //         <div className="flex flex-row font-[Poppins] gap-1">
        //             <div className="loaded-by-ritukar-vijay-span w-full">
        //             {newLogs[i]?.action} <span className="text-left text-[10px] font-[Poppins] ">{(createdAt?.length>1)?(`${createdAt?.[0]}:${createdAt?.[1]}`):""}</span>
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //     )
        //   }

        //   logs.push(
        //     <div className=" flex flex-row items-start justify-between my-2">
        //       {temp[0]}
        //       {temp?.[1]}
        //     </div>
        //   )
        //   i++;
        // }

        log?.logs?.map((item) => {
          let createdAt = new Date(item.createdAt)
            .toLocaleTimeString("en-US", {
              timeZone: fleetData.map.timeZone,
              hour12: false,
            })
            .split(":");

          if (item?.action?.indexOf("unloaded") !== -1) {
            unLoadedLogs.push(
              <div className="flex flex-col gap-2 items-start justify-start shrink-0 w-[175px] ">
                <div className="flex flex-col gap-0 items-start justify-start self-stretch shrink-0 ">
                  <div className="text-[#ffffff] text-left font-['-',_sans-serif] text-sm font-normal  self-stretch flex items-center justify-start">
                    <div className="flex flex-col font-[Poppins] gap-1">
                      <div className="loaded-by-ritukar-vijay-span w-full">
                        {item?.action}{" "}
                      </div>
                      <span className="text-left text-[10px] font-[Poppins] ">
                        {createdAt?.length > 1
                          ? `${createdAt?.[0]}:${createdAt?.[1]}`
                          : ""}
                      </span>
                    </div>{" "}
                  </div>
                </div>
              </div>
            );
          } else if (item?.action?.indexOf("loaded") !== -1) {
            loadedLogs.push(
              <div className="flex flex-col gap-2 items-start justify-start shrink-0 w-[175px] ">
                <div className="flex flex-col gap-0 items-start justify-start self-stretch shrink-0 ">
                  <div className="text-[#ffffff] text-left font-['-',_sans-serif] text-sm font-normal  self-stretch flex items-center justify-start">
                    <div className="flex flex-col font-[Poppins] gap-1">
                      <div className="loaded-by-ritukar-vijay-span w-full">
                        {item?.action}{" "}
                      </div>
                      <span className="text-left text-[10px] font-[Poppins] ">
                        {createdAt?.length > 1
                          ? `${createdAt?.[0]}:${createdAt?.[1]}`
                          : ""}
                      </span>
                    </div>{" "}
                  </div>
                </div>
              </div>
            );
          }
        });

        if(!log?.loadLocation?.[0]){
          return null;
        }

        // if (loadedLogs?.length <= 0 && unLoadedLogs?.length <= 0) {
        //   return null;
        // }    

        let loadTime = new Date(log?.loadTime?.[0])
          .toLocaleTimeString("en-US", {
            timeZone: fleetData.map.timeZone,
            hour12: false,
          })
          .split(":");
        let unloadTime = new Date(log?.unloadTime?.[0])
          .toLocaleTimeString("en-US", {
            timeZone: fleetData.map.timeZone,
            hour12: false,
          })
          .split(":");

        return (
          <div className="bg-[#272f42] max-h-[550px] h-full p-3 rounded-lg shrink-0 w-[443px] overflow-hidden">
            <div className="flex flex-col items-center justify-start w-full mb-2 h-[30px]">
              <div className="flex flex-row h-full items-start justify-between self-stretch shrink-0">
                <div className="text-[#ffffff] text-left font-['Poppins-Bold',_sans-serif] text-base font-bold uppercase flex items-center justify-start min-w-[87px]">
                  {log?.loadLocation?.[0]}
                </div>
                <div className="flex h-full flex-row gap-[3px] items-center justify-between shrink-0 w-[200px] relative ">
                  <div className="flex mb-auto flex-col gap-0 items-start justify-start self-stretch shrink-0 w-[40px]">
                    <div className="text-[#ffffff] text-left font-[Poppins] text-[10px] font-medium  self-stretch ">
                      {loadTime?.length > 1
                        ? `${loadTime?.[0]}:${loadTime?.[1]}`
                        : ""}
                    </div>
                  </div>
                  {!(unLoadedLogs.length<=0 && loadedLogs.length<=0 )  && <div className="w-full h-0 overflow-visible flex flex-row absolute mb-[5px]">
                    <div className="relative w-full h-0">
                      <div className="border-t-[1px] border-t-white border-dashed w-full"></div>
                      <p class="absolute right-[-6px] top-[-9px] flex items-center">
                        <span class="w-0 h-0 m-[5px] border-t-[4px] border-b-[4px] border-l-[6px] border-l-solid border-l-white border-t-transparent border-b-transparent"></span>
                      </p>
                    </div>
                  </div>}
                  <div className="text-[#ffffff] mt-auto w-[60px] text-right font-[Poppins] text-[10px] font-medium  self-stretch">
                    {unloadTime?.length > 1
                      ? `${unloadTime?.[0]}:${unloadTime?.[1]}`
                      : ""}
                  </div>
                </div>
                <div className="text-[#ffffff] text-left font-['Poppins-Bold',_sans-serif] text-base font-bold uppercase  flex items-center justify-start min-w-[87px]">
                  {log?.unloadLocation?.[0]}
                </div>
              </div>
            </div>
            <div className="bg-[#1a1e2a] h-[calc(100%-42px)] rounded-lg pt-2 pr-3 pb-2 pl-3 flex flex-row justify-between overflow-y-scroll">
             {(unLoadedLogs.length<=0 && loadedLogs.length<=0 )?<div className="text-gray-300 flex justify-center items-center w-full h-full"><span className="text-sm">Trip Skipped as no one loaded</span></div>:<> <div className="flex flex-col w-fit">{loadedLogs}</div>
              <div className="flex flex-col w-fit">{unLoadedLogs}</div></>}
            </div>
          </div>
        );
      });
    }
    if(logsCards.length==1&&logsCards[0]==null){
      return null
    }
    return logsCards;
  };

  return (
    <div className={"flex flex-col items-start justify-between w-full"}>
      <div className="flex flex-row h-[30px] items-center justify-start overflow-scroll no-scrollbar w-full">
        {getDatesInRange()}
      </div>
      {(logsData.length > 0 && getLogsCards())? (
        <div className="flex flex-row gap-[9px] items-center justify-start self-stretch shrink-0 overflow-scroll w-full no-scrollbar h-[calc(100%-46px)]">
          {getLogsCards()}
        </div>
      ) : (
        <div className="text-white flex justify-center items-center h-[calc(100%-20px)] w-full bg-[#282F42] mt-4">
          <div>No Logs to show</div>
        </div>
      )}
    </div>
  );
};

// <div className="bg-[#272f42] h-full p-3 rounded-lg shrink-0 w-[443px] overflow-hidden">
// <div className="flex flex-col items-center justify-start w-full mb-2 h-[30px]">
//   <div className="flex flex-row h-full items-start justify-between self-stretch shrink-0">
//     <div className="text-[#ffffff] text-left font-['Poppins-Bold',_sans-serif] text-base font-bold uppercase flex items-center justify-start">
//       mtc_nORTH{" "}
//     </div>
//     <div className="flex h-full flex-row gap-[3px] items-center justify-between shrink-0 w-[200px] relative ">
//       <div className="flex mb-auto flex-col gap-0 items-start justify-start self-stretch shrink-0 w-[40px]">
//         <div className="text-[#ffffff] text-left font-[Poppins] text-[10px] font-medium  self-stretch ">
//           15:15{" "}
//         </div>
//       </div>
//       <div className="w-full h-0 overflow-visible flex flex-row absolute mb-[5px]">
//         <div className="relative w-full h-0">
//             <div className="border-t-[1px] border-t-white border-dashed w-full"></div>
//             <p class="absolute right-[-6px] top-[-9px] flex items-center"><span class="w-0 h-0 m-[5px] border-t-[4px] border-b-[4px] border-l-[6px] border-l-solid border-l-white border-t-transparent border-b-transparent"></span></p>
//         </div>
//     </div>
//       <div className="text-[#ffffff] mt-auto w-[60px] text-right font-[Poppins] text-[10px] font-medium  self-stretch">
//         15:24{" "}
//       </div>
//     </div>
//     <div className="text-[#ffffff] text-left font-['Poppins-Bold',_sans-serif] text-base font-bold uppercase  flex items-center justify-start">
//       mtc_east{" "}
//     </div>
//   </div>
// </div>
// <div className="bg-[#1a1e2a] h-[calc(100%-42px)] rounded-lg pt-2 pr-3 pb-2 pl-3 flex flex-row items-start justify-between">
//   <div className="flex flex-col gap-2 items-start justify-start shrink-0 w-[175px] ">
//     <div className="flex flex-col gap-0 items-start justify-start self-stretch shrink-0 ">
//       <div className="text-[#ffffff] text-left font-['-',_sans-serif] text-sm font-normal  self-stretch flex items-center justify-start">
//         <div className="flex flex-row font-[Poppins] gap-1">
//           <div className="loaded-by-ritukar-vijay-span w-[50%]">
//             Loaded by : <span className="text-left text-[10px] font-[Poppins] ">14:56</span>
//           </div>
//           <div className="loaded-by-ritukar-vijay-span2 w-[50%] text-green-400">
//             Ramon Becker
//           </div>
//         </div>{" "}
//       </div>
{
  /* <div className="text-[#ffffff] text-left font-[Poppins] text-[10px] font-normal  self-stretch flex items-center justify-start">
          14:56{" "}
        </div> */
}
//   </div>
// </div>
// <div className="flex flex-col items-end justify-start shrink-0 w-40">
//   <div className="flex flex-col gap-0 justify-start shrink-0 w-full ">
//     <div className="text-[#ffffff] text-left font-['-',_sans-serif] text-sm font-normal  self-stretch flex items-center justify-start">
//     <div className="flex flex-row font-[Poppins] gap-1">
//         <div className="loaded-by-ritukar-vijay-span w-[65%]">
//           Unloaded by : <span className="text-[10px] text-left font-[Poppins] ">15:25</span>
//         </div>
//         <div className="loaded-by-ritukar-vijay-span2 w-[35%] text-green-400">
//         Ned Jenkins
//         </div>
//       </div>
{
  /* <span>
            <span className="unloaded-by-hardik-span">
              Unloaded by :
            </span>
            <span className="unloaded-by-hardik-span2"> Ned Jenkins</span>
          </span>{" "} */
}
// </div>
{
  /* <div className="text-[#ffffff] text-right font-[Poppins] text-[10px]  font-normal  self-stretch flex items-center ">
          15:25{" "}
        </div> */
}
//       </div>
//     </div>
//   </div>
// </div>
