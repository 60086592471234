import { useState } from "react";
import { useDispatch } from "react-redux";
import { enddate, startdate } from "../redux/Actions";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";

const Calendar = ({setPage}) => {
  const fleetData = JSON.parse(localStorage.getItem("fleetData"));
  // const timezone = fleetData[0]?.timezone;
  const timezone = "America/New_York";
  const formattedDate = (date) => {
    const dateToBeFormatted = new Date(date);
    const yyyy = dateToBeFormatted.getFullYear();
    let mm = dateToBeFormatted.getMonth() + 1; // Months start at 0!
    let dd = dateToBeFormatted.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    return dd + "-" + mm + "-" + yyyy;
  };
  const [dates, setDatesState] = useState({
    startDate: formattedDate(new Date()),
    endDate: formattedDate(new Date()),
  });
  const dispatch = useDispatch();


  const setDates = (e, { startDate, endDate }) => {
    setDatesState({
      startDate: startDate.format("DD-MM-YYYY"),
      endDate: endDate.format("DD-MM-YYYY"),
    });
    dispatch(startdate(startDate));
    dispatch(enddate(endDate));
    setPage(1)
  };
  const yesterdayDate = () => {
    const currentDate = new Date();
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);
    return [yesterday, yesterday];
  };
  const last7days = () => {
    const currentDate = new Date();
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 6);
    return [yesterday, currentDate];
  };
  const last30days = () => {
    const currentDate = new Date();
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 29);
    return [yesterday, currentDate];
  };
  const thismonth = () => {
    const currentDate = new Date();
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const startOfNextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );
    const endOfMonth = new Date(startOfNextMonth.getTime() - 1);
    // console.log('startOfMonth,endOfMonth',startOfMonth,endOfMonth)
    return [startOfMonth, endOfMonth];
  };
  const lastmonth = () => {
    const currentDate = new Date();
    const startofpreviousMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      1
    );
    const startOfcurrentmonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfpreviousMonth = new Date(startOfcurrentmonth.getTime() - 1);
    // console.log('startofpreviousMonth',startofpreviousMonth,'endOfpreviousMonth',endOfpreviousMonth)
    return [startofpreviousMonth, endOfpreviousMonth];
  };
  const ranges = {
    All: [
      new Date("2022-01-01"),
      new Date(),
    ],
    Today: [
      new Date(),
      new Date(),
    ],
    Yesterday: yesterdayDate(),
    "Last 7 Days": last7days(),
    "Last 30 Days": last30days(),
    "This Month": thismonth(),
    "Last Month": lastmonth(),
  };
  return (
    <>
      <div className="text-white text-sm bg-[#282F42] py-2.5 px-3 rounded-md relative">
        <DateRangePicker
          onApply={setDates}
          initialSettings={{
            startDate: new Date(),
            endDate: new Date(),
            ranges: ranges,
          }}
        >
          <input
            type="text"
            value={dates.startDate + " to " + dates.endDate}
            className="w-full bg-transparent focus:outline-none"
          />
        </DateRangePicker>
        <img
          className="absolute right-5 top-[50%] -translate-y-[50%] w-5 h-5 pointer-events-none"
          src="/assets/images/Caledar_icon.svg"
          alt="calendar"
        />
      </div>
    </>
  );
};

export default Calendar;
